<template>
    <div class="hamburger-menu">
        <button @click="toggleMenu" class="menu-button">&#9776;</button>
        <div v-if="isOpen" class="menu-options" :class="menuDirection" ref="menuOptions">
            <ul>
                <li v-for="(option, index) in options" :key="index" @click="OptionClick(option)" class="menu-item align-center d-flex justify-end">
                    {{ option.title }}
                    <svg-icon v-if="option.mdiIcon" type="mdi" :path="option.mdiIcon" class="ml-2"></svg-icon>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true,
            default: () => [],
        },
        direction: {
            type: String,
            default: "right",
            validator: (value) => ["left", "right"].includes(value),
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        menuDirection() {
            return this.direction === "right" ? "menu-right" : "menu-left";
        },
    },
    methods: {
        toggleMenu() {
            this.isOpen = !this.isOpen;
            if (this.isOpen) {
                document.addEventListener("click", this.handleOutsideClick);
            } else {
                document.removeEventListener("click", this.handleOutsideClick);
            }
        },
        closeMenu() {
            this.isOpen = false;
            document.removeEventListener("click", this.handleOutsideClick);
        },
        handleOutsideClick(event) {
            if (this.$refs.menuOptions && !this.$refs.menuOptions.contains(event.target) && event.target !== this.$el.querySelector(".menu-button")) {
                this.closeMenu();
            }
        },
        OptionClick(option) {
            option.action();
            this.closeMenu();
        },
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleOutsideClick);
    },
};
</script>

<style scoped>
.hamburger-menu {
    position: relative;
    display: inline-block;
}

.menu-button {
    font-size: 20px;
    cursor: pointer;
    background-color: #444444;
    border: none;
    color: white;
    padding: 0px 10px;
    border-radius: 5px;
    transition: background-color 0.1s ease;
}

.menu-button:hover {
    background-color: #111111; /* Color más oscuro en hover */
}

.menu-options {
    position: absolute;
    top: 100%;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 0;
    margin: 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra moderna */
    z-index: 1000;
    transition:
        transform 0.1s ease,
        opacity 0.1s ease; /* Transición suave */
}

.menu-left {
    left: 0;
}

.menu-right {
    right: 0;
}

.menu-options ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu-options li {
    font-size: 1rem;
    padding: 12px 20px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
    color: #000000;
    transition:
        background-color 0.3s ease,
        color 0.3s ease; /* Transiciones en hover */
}

.menu-options li:hover {
    background-color: #e1e1e1; /* Color claro en hover */
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05); /* Sombra interna en hover */
}

.menu-options li:not(:last-child) {
    border-bottom: 1px solid #ddd; /* Línea divisoria entre opciones */
}

.menu-options li:last-child {
    border-radius: 0 0 8px 8px; /* Bordes redondeados en la última opción */
}

.menu-options li:first-child {
    border-radius: 8px 8px 0 0; /* Bordes redondeados en la primera opción */
}
</style>
