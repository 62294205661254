class Axis {
    constructor(title) {
        this.title = title;
    }
}

class SerieItem {
    constructor(x, y, color, range) {
        this.x = x;
        this.y = y;
        this.color = color;
        this.range = {
            from: range?.from ?? 0,
            to: range?.to ?? 0
        };
    }
}

class ChartOptions {
    constructor(yAxisTitle, xAxisTitle, serieItems) {
        this.yAxis = new Axis(yAxisTitle);
        this.xAxis = new Axis(xAxisTitle);
        this.serie = serieItems.map(item => new SerieItem(item.x, item.y, item.color, item.range));
    }
}

const defaultOptions = {
    yAxisTitle: "Eje Y",
    xAxisTitle: "Eje X",
    serieItems: [
        {
            x: "Item 1",
            y: 10,
            color: "#FF0000",
            range: {
                from: 0,
                to: 10
            }
        }, {
            x: "Item 2",
            y: 20,
            color: "#00FF00",
            rangeFrom: 10,
            range: {
                from: 10,
                to: 20
            }
        }, {
            x: "Item 3",
            y: 30,
            color: "#0000FF",
            rangeFrom: 20,
            range: {
                from: 20,
                to: 30
            }
        }
    ]
};

/**
 * Crea un objeto ChartOptions con la estructura adecuada
 * 
 * @param {string} yAxisTitle - Título para el eje Y
 * @param {string} xAxisTitle - Título para el eje X
 * @param {Array<{x: string, y: number, color: string}>} serieItems - Array de objetos con x, y y color para la serie de datos
 * @returns {ChartOptions} - Objeto con la estructura correcta para las opciones del gráfico
 */
export const GetChartOptions = (yAxisTitle, xAxisTitle, serieItems) => {
    // Validaciones simples
    if (!Array.isArray(serieItems)) {
        console.error("serieItems:", serieItems);
        return new ChartOptions(defaultOptions.yAxisTitle, defaultOptions.xAxisTitle, defaultOptions.serieItems);
    }

    // Retornar una nueva instancia de ChartOptions con los parámetros proporcionados
    return new ChartOptions(yAxisTitle, xAxisTitle, serieItems);
};

export const GetChartOptionsFromAmbientationMap = (ambientationMap, removeUselessRanges = true) => {
    // Validaciones simples
    if (!ambientationMap || !ambientationMap.ambientationsInfo) {
        console.error("ambientationMap:", ambientationMap);
        return new ChartOptions(defaultOptions.yAxisTitle, defaultOptions.xAxisTitle, defaultOptions.serieItems);
    }

    const serie = [];
    const rangesInfo = _GetRangesInfo(ambientationMap.ambientationsInfo, removeUselessRanges);
    for (const ambientationInfo of rangesInfo) {
        const range = {
            from: ambientationInfo.rangeFrom,
            to: ambientationInfo.rangeTo
        };
        serie.push(new SerieItem(ambientationInfo.rangeFrom, ambientationInfo.ha, ambientationInfo.color, range));
    }
    return GetChartOptions("Hectáreas", ambientationMap.unit, serie);
};

const _GetRangesInfo = (ranges, removeUselessRanges) => {
    const copyOfRanges = ranges.map(range => {
        return {
            rangeFrom: range.rangeFrom,
            rangeTo: range.rangeTo,
            ha: Math.round(range.ha),
            color: range.color,
        };
    });
    const serie = [];
    for (let i = 0; i < copyOfRanges.length; i++) {
        const range = copyOfRanges[i];
        if (!removeUselessRanges) {
            serie.push(range);
            continue;
        }

        const isCurrentZero = range.ha === 0;
        const isPrevZero = i > 0 ? copyOfRanges[i - 1].ha === 0 : true; // Si es el primero, asumimos que no tiene anterior con 0
        const isNextZero = i < copyOfRanges.length - 1 ? copyOfRanges[i + 1].ha === 0 : true; // Si es el último, asumimos que no tiene siguiente con 0
        if (isCurrentZero && isPrevZero && isNextZero) continue;

        serie.push(range);
    }
    return serie;
};

