import * as BaseServices from "./base.services";

export class RecommendationReportsServices {
    static async GetAmbientationMapAsync(ambientationMapId) {
        const response = await BaseServices.AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/ambientation-map/${ambientationMapId}`);
        if (!response.data) return response.error;
        return response.data;
    }

    static async GetRecommendationReportsAsync(fieldId) {
        const response = await BaseServices.AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/recommendation-reports/${fieldId}`);
        if (!response.data) return null;
        if (response.data.reports.length > 0) response.data.reports.sort((a, b) => b.id - a.id);
        return response.data;
    }

    static async PostRecommendationReportAsync(fieldId, body) {
        const url = `${process.env.VUE_APP_API_URL}/v1/recommendation-report/${fieldId}`;
        const result = await BaseServices.AxiosPostAsync(url, body);
        return result;
    }

    static async DeleteRecommendationReportAsync(reportId) {
        const url = `${process.env.VUE_APP_API_URL}/v1/recommendation-report/${reportId}`;
        const result = await BaseServices.AxiosDeleteAsync(url);
        return result;
    }

    static async GetRecommendationReportAsync(reportId) {
        const response = await BaseServices.AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/recommendation-report/${reportId}`);
        return response.data;
    }

    /**
     * @param {Number} reportId
     * @param {String} finalRecommendations
     * @param {Nutrient[]} nutritionalPlanByNutrient
     * @param {Ambient[]} ambientsInfo
     * @param {String} campaign
     */
    static async SaveRecommendationReportAsync(reportId, finalRecommendations, nutritionalPlanByNutrient, ambientsInfo, campaign) {
        const url = `${process.env.VUE_APP_API_URL}/v1/recommendation-report/${reportId}`;
        const result = await BaseServices.AxiosPatchAsync(url, { finalRecommendations, nutritionalPlanByNutrient, ambientsInfo, campaign });
        return result.error == null;
    }
}
