<template>
    <v-app id="app" ref="App">
        <!-- TODO: El mismo componente podría ejecutar la lógica de la conexión y mostrar el modal -->
        <processing-dialog :processing="processingDialogVisibility" />

        <div class="FondoVistas" v-show="ShowGUI"></div>

        <GUI v-if="ShowGUI && !hideModalAndFooter" />

        <div class="full-height">
            <div v-if="$route.name != 'Login' && !hideModalAndFooter" class="fix-for-the-header"></div>

            <bread-crumb />

            <router-view :key="$route.path" />

            <my-go-top-button class="hiddenForMobile" />

            <ActivityBarMobile />
        </div>

        <pwa-button />
        <new-available-version />

        <notifications group="Notificaciones" position="bottom right" />
        <div class="DivSinConexion align-center" :class="{ Visible: !online }">Sin conexión...</div>
    </v-app>
</template>

<script>
import GUI from "./components/GUI.vue";
import { Herramientas } from "./Auxiliares/Enums";
import { mapActions, mapState, mapGetters } from "vuex";
import { LoadJsApiLoaderAsync, isGoogleMapsLoaded } from "./plugins/google.js.api.loader";
import ActivityBarMobile from "./components/ActivityBarMobile.vue";

export default {
    name: "App",
    components: {
        GUI,
        ActivityBarMobile,
    },
    data() {
        return {
            Datos: {
                _IP: "",
                _User: null,
            },
            idHerramienta: -1,
            showGUI: false,
        };
    },
    async created() {
        if (!isGoogleMapsLoaded) await LoadJsApiLoaderAsync();
        //TODO: Agregar regla para mostrar algo como "pagina no autorizada" si se accede a /producer sin tener el rol necesario
        this.setPublicPage(this.$route.name);
        this.setUserFromLocalStorage();
        if (!this.publicPage) {
            if (this.user == null) {
                this.$router.push({ name: "Login" });
            }
        }
    },
    async mounted() {
        window.addEventListener("online", this.updateOnlineStatus);
        window.addEventListener("offline", this.updateOnlineStatus);

        this.SetIdHerramienta();

        console.log(`Versión: ${this.getVersion}`);
    },
    beforeDestroy() {
        window.removeEventListener("online", this.updateOnlineStatus);
        window.removeEventListener("offline", this.updateOnlineStatus);
    },
    watch: {
        async $route() {
            this.SetIdHerramienta();

            await this.TrySetProducerAndFieldInfoAsync();
            this.SetBreadcrumbItemsFromUrl();
        },
    },
    methods: {
        ...mapActions(["setPublicPage", "setOnline"]),
        ...mapActions("userStore", ["setUserFromLocalStorage"]),
        ...mapActions("breadcrumbStore", ["SetBreadcrumbItemsFromUrl"]),
        ...mapActions("generalsStore", ["TrySetProducerAndFieldInfoAsync"]),
        SetIdHerramienta() {
            const IdHerramientaSeccion = Herramientas.GetIdHerramientaByRouteName(this.$router.currentRoute.name);
            this.idHerramienta = -1;
            if (IdHerramientaSeccion > 0) this.idHerramienta = IdHerramientaSeccion;
            if (this.$router.currentRoute.name == "Home") this.idHerramienta = 0;
        },
        updateOnlineStatus(e) {
            const { type } = e;
            this.setOnline(type === "online");
        },
        hideHeaderAndFooter(data) {
            // console.log(data)
            this.hideModalAndFooter = data;
        },
    },
    computed: {
        ...mapState(["publicPage", "hideModalAndFooter"]),
        ...mapGetters("generalsStore", ["getVersion"]),
    },
};
</script>

<style lang="scss">
@import "./css/Globales.scss";
@import "./css/Componentes.scss";
@import "./css/modals-and-inputs.scss";
@import url("https://use.fontawesome.com/releases/v5.5.0/css/all.css");

.FondoVistas {
    background-color: rgba(255, 255, 255, 0.7);
}

#app {
    text-align: center;
    color: #000;
    width: 100vw;
    min-height: 100vh;
    display: block;
    font-size: 20px;
    // background-color: rgba(0, 0, 0, 0.05);
    overflow: hidden;

    @media (max-width: 800px) {
        font-size: 18px;
    }
}

.fs12rem {
    font-size: 1.2rem;
}

.FondoVistas {
    background-color: $primary;
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
    z-index: -1;
    display: block;
}

.bgImageContainer {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
    z-index: -1;
    display: block;
}

.bgImage {
    min-height: 100%;
    min-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

// .vue-notification-group{
//   // width:250px !important;
//   // right:5px !important;
// }
.vue-notification {
    padding: 10px;
    margin: 0 5px 5px;
    // width:250px;

    font-size: 12px;

    color: #ffffff;
    // background: #0d6b29;
    // border-left: 5px solid #00ad34;

    &.warn {
        background: #92692c !important;
        border-left-color: #f48a06 !important;
    }

    &.error {
        background: #e54d42;
        border-left-color: #b82e24;
    }

    &.success {
        // background: #0d6b29;
        // border-left: 5px solid #00ad34;
        background: #00ad34 !important;
        border-left-color: #008f2b !important;
    }
}

.BotonNo {
    background: #999;
    width: 60px;
    margin: auto 10px;
}

.BotonSi {
    background: $primary;
    width: 60px;
    margin: auto 10px;
}

.BotonOK {
    background: $primary;
    width: 60px;
    margin: auto;
}

.swal2-actions {
    justify-content: space-around;
    width: 100%;
}

.swal2-popup {
    font-size: 1.1em;
}

.DivSinConexion {
    z-index: 9;
    position: fixed;
    bottom: 0px;
    color: #000;
    // background: rgb(185, 10, 10);
    background: linear-gradient(to right, red, orange);
    box-sizing: border-box;
    box-shadow: inset 0 0 1px #000;
    line-height: 40px;
    text-align: center;
    opacity: 1;
    transition: all 0.1s linear;
    max-height: 0px;
    overflow: hidden;
    font-size: 1.4rem;
    width: 100%;
    left: 0;
    // transform: translate(-50%, 0);
    box-shadow: 0 0 10px #000;
    font-weight: 500;

    &.Visible {
        max-height: 40px !important;
    }

    @media (max-width: 800px) {
        bottom: 60px;
        font-size: 1.4rem;
    }
}

.fix-for-the-header {
    display: block;
    width: 100%;
    height: $header-height;

    @media (max-width: 799px) {
        display: none;
    }
}

.hiddenForMobile {
    @media (max-width: 799px) {
        display: none !important;
    }
}
</style>
