<template>
    <span class="p-relative">
        <span :contenteditable="isEditing" @keydown="HandleKeydown" ref="editableSpan" class="fix-for-btn-edit">{{ value
            }}</span>

        <v-btn v-if="!isProducerView && allowEdition && !isEditing && getVisibilityEditionButtons"
            class="color-primary btn" small fab @click.prevent="ToggleToEditMode()">
            <svg-icon type="mdi" :path="mdiPencilOutline" class=""></svg-icon>
        </v-btn>

        <span v-if="isEditing" class="btn">
            <v-btn color="default" @click="ResetClick()" small fab>
                <my-tooltip text="Escape para cancelar" :custom-styles="{ fontSize: '1.4rem' }">
                    <svg-icon type="mdi" :path="mdiWindowClose" class=""></svg-icon>
                </my-tooltip>
            </v-btn>
            <v-btn color="primary" @click="UpdateValueClick()" small fab class="ml-2">
                <my-tooltip text="CTRL+ENTER para guardar" :custom-styles="{ fontSize: '1.4rem' }">
                    <svg-icon type="mdi" :path="mdiCheckBold" class=""></svg-icon>
                </my-tooltip>
            </v-btn>
        </span>
    </span>
</template>

<script>
import { NotifyInfo } from "@/helpers/vue-notifications.helper";
import { mdiPencilOutline, mdiWindowClose, mdiCheckBold } from "@mdi/js";
import { mapGetters } from "vuex";
export default {
    props: {
        value: {
            type: [String, Number],
            required: true,
        },
        allowEdition: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isEditing: false,
            mdiPencilOutline, mdiWindowClose, mdiCheckBold,
        };
    },
    methods: {
        UnselectEditableSpan() {
            const selection = window.getSelection();
            selection.removeAllRanges();
        },
        SelectAllEditableSpan() {
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(this.$refs.editableSpan);
            selection.removeAllRanges();
            selection.addRange(range);
        },
        ToggleToEditMode() {
            this.isEditing = true;

            this.$nextTick(() => {
                this.$refs.editableSpan.focus();

                this.SelectAllEditableSpan();
            });
        },
        ResetClick() {
            this.UnselectEditableSpan();
            this.$refs.editableSpan.innerText = this.value;
            this.isEditing = false;
        },
        async UpdateValueClick() {
            this.UnselectEditableSpan();
            const newValue = this.$refs.editableSpan.innerText.trim();
            this.isEditing = false;

            if (typeof this.value == "number") {
                if (isNaN(newValue)) {
                    this.$refs.editableSpan.innerText = this.value;
                    return NotifyInfo("El valor debe ser un número.");
                }
            }

            if (newValue == this.value) return;

            this.$emit("on-change", newValue);
        },
        HandleKeydown(event) {
            if (!this.isEditing) return;

            if (event.key == "Enter") {
                event.preventDefault();
                return this.UpdateValueClick();
            }

            if (event.key == "Escape") {
                event.preventDefault();
                return this.ResetClick();
            }
        }
    },
    computed: {
        ...mapGetters("generalsStore", ["getVisibilityEditionButtons"]),
        ...mapGetters("userStore", ["isProducerView"]),
        getInputType() {
            return typeof this.value === "number" ? "number" : "text";
        },
    },
};
</script>

<style lang="scss" scoped>
.btn {
    top: -5px;
    margin-left: 5px;
}

.fix-for-btn-edit {
    padding-left: 0px;
    outline: none;
}
</style>
