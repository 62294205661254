import { AxiosGetAsync } from "@/services/base.services";
import { router } from "@/router/NewRouter";

const GetDefaultState = () => {
    return {
        producer: {
            id: "",
            name: "",
            businessName: "",
        },
        field: {
            id: "",
            name: "",
            city: "",
            totalHa: "",
            parentField: "",
        },
        version: "1.1.220",
        visibilityEditionButtons: true,
    };
};

export default {
    namespaced: true,
    state: GetDefaultState(),
    getters: {
        getProducer(state) {
            return {
                id: state.producer.id,
                name: state.producer.name,
                businessName: state.producer.businessName,
            };
        },
        getField(state) {
            return state.field;
        },
        getVersion(state) {
            return state.version;
        },
        getVisibilityEditionButtons(state) {
            return state.visibilityEditionButtons;
        },
    },
    mutations: {
        SetProducer(state, { name, id, businessName }) {
            if (businessName == null) return;

            state.producer.id = id;
            state.producer.name = name;
            state.producer.businessName = businessName;
        },
        SetField(state, { name, id, city, totalHa, parentField }) {
            state.field.name = name;
            state.field.id = id;
            state.field.city = city;
            state.field.totalHa = totalHa;
            state.field.parentField = parentField;
        },
        SetVisibilityAllowEditionButtons(state, value) {
            state.visibilityEditionButtons = value;
        },
    },
    actions: {
        async TrySetProducerAndFieldInfoAsync({ commit }) {
            const producerId = router.currentRoute.params.producerId;
            if (!producerId) return false;

            const fieldId = router.currentRoute.params.fieldId;

            const axiosResult = await AxiosGetAsync(`${process.env.VUE_APP_API_URL}/v1/producer-and-field/${producerId}/${fieldId}`);
            if (axiosResult.error) return false;

            /** @type { { producer: { ID, Nombre, Apellido, BusinessName }, field: { ID, Nombre } } }  */
            const producerAndFieldInfo = axiosResult.data;

            const producer = producerAndFieldInfo.producer;
            commit("SetProducer", {
                id: producer.ID,
                name: `${producer.Nombre} ${producer.Apellido}`,
                businessName: producer.BusinessName,
            });

            const field = producerAndFieldInfo.field;
            if (field) {
                commit("SetField", {
                    id: field.ID,
                    name: field.Nombre,
                    city: field.Localidad,
                    totalHa: field.HaTotals,
                    parentField: field.ParentField,
                });
            }

            return true;
        },
        SetVisibilityAllowEditionButtons({ commit }, value) {
            commit("SetVisibilityAllowEditionButtons", value);
        },
    },
};
