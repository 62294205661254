<template>
    <v-card class="ambientation-container" v-if="ambientationMap">
        <v-row no-gutters>
            <v-col cols="12" class="pa-1">
                <div class="w-100 d-flex align-center justify-start px-2 my-2">
                    <my-tooltip v-if="userAdmin && !isProducerView" :text="getTooltipTitle">
                        <div @click="ChangeVisibilityAsync(ambientationMap)" class="align-center d-flex">
                            <svg-icon :size="30" type="mdi" class="cursor-pointer" :path="getIcon"
                                :class="GetEyeIconClasses(ambientationMap)" />
                        </div>
                    </my-tooltip>
                    <div class="text-h6 ml-2" v-html="GetAmbientationTitle(ambientationMap)"></div>
                </div>

                <satelital-map :geo-image="ambientationMap.geoImage" />
            </v-col>
            <v-col cols="12" class="pa-1">
                <vertical-chart :chartOptions="GetChartOptionsFromAmbientationMap(ambientationMap)" />
                <ambientation-categories-panel class="mt-2" :ambientation="ambientationMap" :expanded="true"
                    :showHighlightedCategoryAsTitle="true" :showMethodology="true" />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { GetChartOptionsFromAmbientationMap } from "@/views/shared-components/VerticalChartUtils";
import { mdiEyeOff, mdiEye } from "@mdi/js";
import { mapActions, mapGetters } from "vuex";
import { GetFirstLetterOfEachWord } from "@/helpers/string.helper";

export default {
    components: {},
    props: {
        ambientationMap: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            mdiEyeOff,
            mdiEye,
            GetChartOptionsFromAmbientationMap,
        };
    },
    methods: {
        ...mapActions("ambientationsSection3Store", ["SetVisibilityOfAnAmbientationAsync"]),
        ChangeVisibilityAsync(ambientationMap) {
            const newVisibility = !(ambientationMap.visibleForClients ?? true);
            this.SetVisibilityOfAnAmbientationAsync(ambientationMap.id, newVisibility);
        },

        GetAmbientationTitle(ambientationMap) {
            if (!this.userAdmin || this.isProducerView) return this.GetAmbientationTitleForProducer(ambientationMap);

            return `${this.GetAmbientationTitleForAdmin(ambientationMap)} (${GetFirstLetterOfEachWord(ambientationMap.range_type)})`;
        },
        GetAmbientationTitleForAdmin(ambientationMap) {
            if (ambientationMap.layers.length == 1) {
                const auxiliarText = ambientationMap.layers[0].shortName;
                if (ambientationMap.layers[0].invert) return `<span class="inverted-layer">${auxiliarText}</span>`;
                return auxiliarText;
            }

            const getTitle = (layer) => {
                const cropTypePart = layer.cropType ? `${layer.cropType}` : "";
                const yearPart = layer.year ? `${layer.year}` : "";
                //TODO: La condición this.userAdmin de abajo debería ser redundante, ya que estamos en un método exclusivo para el admin
                const weightPart = this.userAdmin && layer.weight && ` (${layer.weight}%)`;

                const auxiliarText = `${layer.shortName} ${cropTypePart}${yearPart}${weightPart}`;
                if (layer.invert) return `<span class="inverted-layer">${auxiliarText}</span>`;

                return auxiliarText;
            };

            return ambientationMap.layers.map((layer) => getTitle(layer)).join(", ");
        },
        GetAmbientationTitleForProducer(ambientation) {
            if (ambientation.layers.length == 1) return ambientation.layers[0].name;

            return "Ambientación";
        },
        VisibleAmbientationMap(ambientationMap) {
            return ambientationMap.visibleForClients ?? true;
        },
        GetEyeIconClasses(ambientationMap) {
            if (this.VisibleAmbientationMap(ambientationMap)) return "color-info";
        },
    },
    computed: {
        ...mapGetters("userStore", ["isProducerView"]),
        getTooltipTitle() {
            const id = this.ambientationMap.id;
            if (this.ambientationMap.visibleForClients ?? true) return `Ocultar ambientación (id: ${id})`;

            return `Mostrar ambientación (id: ${id})`;
        },
        getIcon() {
            if (this.ambientationMap.visibleForClients ?? true) return this.mdiEye;

            return this.mdiEyeOff;
        },
    },
};
</script>

<style scoped>
.ambientation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(250, 250, 250);
}

.ambientation-column,
.map-column {
    height: 100%;
}

.ambientation-column {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.layer-title {
    font-size: 1.5rem;
    font-weight: bold;
}
</style>

<style lang="scss">
.ambientation-container {
    .v-expansion-panel-header {
        background-color: #CCCCCC;
    }
}
</style>
