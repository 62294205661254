import { render, staticRenderFns } from "./VerticalChart.vue?vue&type=template&id=3a035f01&scoped=true"
import script from "./VerticalChart.vue?vue&type=script&lang=js"
export * from "./VerticalChart.vue?vue&type=script&lang=js"
import style0 from "./VerticalChart.vue?vue&type=style&index=0&id=3a035f01&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a035f01",
  null
  
)

export default component.exports