<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs" class="slot-content">
                <slot></slot>
            </div>

        </template>
        <span class="my-tooltip" :style="customStyles">{{ text }}</span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: "",
        },
        customStyles: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style scoped>
.my-tooltip {
    max-width: 600px;
    display: block;
}

.slot-content {
    display: inline-block;
}
</style>
