const months = ["Enero", "Febrero", "Marzo", "Abril", "Nayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const AmbientationFilesType = {
    Soil: {
        name: "SoilOptix",
        active: true,
        shortName: "Soil",
    },
    Veris: {
        name: "Veris",
        active: true,
    },
    MapaRendimiento: {
        name: "Mapa Rendimiento",
        active: true,
        shortName: "Mapa",
    },
    ImagenesSatelitales: {
        name: "Imagen Satelital",
        active: true,
    },
    Dualem: {
        name: "Dualem",
        active: true,
    },
};

const cerealType = ["Maiz 1", "Maiz 2", "Soja 1", "Soja 2", "Sorgo", "Trigo", "Girasol"];

const GetCropTypeOptions = () => {
    return cerealType.map((item) => {
        return {
            name: item,
            value: item,
            checked: item == "Maiz 1",
        };
    });
};

const CropTypes = {
    Maiz: "Maiz",
    Soja: "Soja",
    Sorgo: "Sorgo",
    Trigo: "Trigo",
    Girasol: "Girasol"
};
export const GetCropTypesOptions = () => {
    return Object.values(CropTypes).map((cropType, index) => {
        return {
            name: cropType,
            value: cropType,
            checked: index == 0 ? true : false,
        };
    });
};

export const GetCampaignOptions = () => {
    const currentYear = new Date().getFullYear();
    return [
        { name: `Campaña ${currentYear + 2}/${currentYear + 3}`, checked: false },
        { name: `Campaña ${currentYear + 1}/${currentYear + 2}`, checked: false },
        { name: `Campaña ${currentYear}/${currentYear + 1}`, checked: true },
        { name: `Campaña ${currentYear - 1}/${currentYear}`, checked: false },
    ];
};

const satelitalImagesType = ["NDVI", "EVI", "Infrarrojo"];

const prescriptionTypeDict = {
    Siembra: "Siembra",
    Fertilizacion: "Fertilización",
};

const GetPrescriptionTypeOptions = () => {
    return Object.values(prescriptionTypeDict).map((prescriptionType) => {
        return {
            name: prescriptionType,
            value: prescriptionType,
            checked: prescriptionType == prescriptionTypeDict.Siembra,
        };
    });
};

const unitsToPrescriptionType = {
    Siembra: ["miles de semillas/ha", "semillas/m", "kilos/ha"],
    Fertilizacion: ["kilos/ha", "litros/ha", "tn/ha"],
};

const GetUnitsForSiembra = () => {
    return unitsToPrescriptionType.Siembra.map((unit) => {
        return {
            name: unit,
            value: unit,
            checked: unit === unitsToPrescriptionType.Siembra[0],
        };
    });
};

const GetUnitsForFertilizacion = () => {
    return unitsToPrescriptionType.Fertilizacion.map((unit) => {
        return {
            name: unit,
            value: unit,
            checked: unit === unitsToPrescriptionType.Fertilizacion[0], // Marca el primero como checked
        };
    });
};

const prescriptionType = Object.keys(prescriptionTypeDict);

const ambientationsMapZoomRanges = [
    { maxRatio: 0.3, zoom: 13 }, //Rectangulo alto
    { maxRatio: 0.4, zoom: 14 },
    { maxRatio: 0.5, zoom: 15 },
    { maxRatio: 1.04, zoom: 14 }, //cuadrados
    { maxRatio: 1.06, zoom: 15 }, //cuadrados
    { maxRatio: 1.45, zoom: 14 },
    { maxRatio: 1.9, zoom: 15 },
    { maxRatio: 10, zoom: 14 }, //Rectangulo ancho
];

const squareThresholdAmbientationMap = 0.05;

const RANGES_TYPES_OF_AMBIENTATIONS = {
    AmbientesIguales: "Espacios Iguales",
    AmbientesManuales: "Espacios Manuales",
    DesviacionEstandar: "Desviación Estandar",
};

const LAYERS_AMBIENTATION_ERROR_MESSAGES = {
    SELECT_AT_LEAST_ONE_LAYER: "Se debe seleccionar al menos una capa.",
    LIMIT_LAYERS: "Sólo se permite combinar entre 1 y 5 capas.",
    TOTAL_PERCENTAGE: "La sumatoria de los porcentajes de cada capa debe ser de un total del 100%.",
    DUPLICATE_AMBIENTATION: "Ya existe una ambientación con tales configuraciones",
    INVALID_POINTS_ORDER: "Todos los puntos deben ser mayores que el anterior.",
    INVALID_MIN_AND_MAX_POINTS: "En ambientaciones con más de una capa, el mínimo debe ser 0 y el máximo 1.",
};

const SoilTypes = [
    { id: 1, name: "Suelos desérticos de Patagonia extrandina", checked: false },
    { id: 2, name: "Suelos del bosque andino-patagónico", checked: false },
    { id: 3, name: "Suelos Semidesérticos de Patagonia austral", checked: false },
    { id: 4, name: "Suelos líticos de los Andes Centrales", checked: false },
    { id: 5, name: "Suelos poco desarrollados y áridos del centro-oeste", checked: false },
    { id: 6, name: "Suelos de la selva Subandina", checked: false },
    { id: 7, name: "Suelos del monte Chaqueño", checked: false },
    { id: 8, name: "Suelos humíferos de la región Pampeana", checked: false },
    { id: 9, name: "Suelos líticos y salinos de la Puna y de la Cordillera Oriental", checked: false },
    { id: 10, name: "Suelos de la Mesopotamia", checked: false },
    { id: 11, name: "Suelos de la Selva subtropical misionera", checked: false },
    { id: 12, name: "Suelos de la Antártida", checked: false },
];
const GetSoilTypes = () => {
    const copy = SoilTypes.map((soil) => {
        return { ...soil };
    });
    return copy.sort((a, b) => a.name.localeCompare(b.name));
};

const SoilsDominant = [
    { id: "dominant - 1", name: "Haplargides", checked: false },
    { id: "dominant - 2", name: "Haplocalcides", checked: false },
    { id: "dominant - 3", name: "Haplustoles", checked: false },
    { id: "dominant - 4", name: "Haplusdalfes", checked: false },
    { id: "dominant - 5", name: "Argiudoles", checked: false },
    { id: "dominant - 6", name: "Asomos rocosos", checked: false },
    { id: "dominant - 7", name: "Natracualfes", checked: false },
    { id: "dominant - 8", name: "Criortentes", checked: false },
    { id: "dominant - 9", name: "Torriortentes", checked: false },
    { id: "dominant - 10", name: "Hapludox", checked: false },
    { id: "dominant - 11", name: "Haplosalides", checked: false },
    { id: "dominant - 12", name: "Haplustalfes", checked: false },
    { id: "dominant - 13", name: "Hapludultes", checked: false },
    { id: "dominant - 14", name: "Haploxeroles", checked: false },
    { id: "dominant - 15", name: "Torripsamentes", checked: false },
    { id: "dominant - 16", name: "Ocracualfes", checked: false },
    { id: "dominant - 17", name: "Girasoles en general", checked: false },
    { id: "dominant - 18", name: "Hapludands", checked: false },
    { id: "dominant - 19", name: "Hapludoles", checked: false },
];

const GetSoilDominant = () => {
    const copy = SoilsDominant.map((soil) => {
        return { ...soil };
    });
    return copy.sort((a, b) => a.name.localeCompare(b.name));
};

const SoilsSubordinates = [
    { id: "subordinates - 1", name: "Natrargides", checked: false },
    { id: "subordinates - 2", name: "Haplocambides", checked: false },
    { id: "subordinates - 3", name: "Haploxeralfes", checked: false },
    { id: "subordinates - 4", name: "Histosoles", checked: false },
    { id: "subordinates - 5", name: "Udivitrands", checked: false },
    { id: "subordinates - 6", name: "Haplocalcides", checked: false },
    { id: "subordinates - 7", name: "Haplosalides", checked: false },
    { id: "subordinates - 8", name: "Haplustoles", checked: false },
    { id: "subordinates - 9", name: "Haplargides", checked: false },
    { id: "subordinates - 10", name: "Paleargides", checked: false },
    { id: "subordinates - 11", name: "Torriortentes", checked: false },
    { id: "subordinates - 12", name: "Hapludoles", checked: false },
    { id: "subordinates - 13", name: "Hapludalfes", checked: false },
    { id: "subordinates - 14", name: "Udipsamentes", checked: false },
    { id: "subordinates - 15", name: "Endoacuoles", checked: false },
    { id: "subordinates - 16", name: "Criortentes", checked: false },
    { id: "subordinates - 17", name: "Cromudertes", checked: false },
    { id: "subordinates - 19", name: "Natracualfes", checked: false },
    { id: "subordinates - 19", name: "Ocracualfes", checked: false },
    { id: "subordinates - 20", name: "Haplocrioles", checked: false },
    { id: "subordinates - 21", name: "Criaacueptes", checked: false },
    { id: "subordinates - 22", name: "Criofibristes", checked: false },
];

const GetSoilSubordinates = () => {
    const copy = SoilsSubordinates.map((soil) => {
        return { ...soil };
    });
    return copy.sort((a, b) => a.name.localeCompare(b.name));
};

const TextureAmbientations = ["Arena", "Limo", "Arcilla"];
const AltitudeAmbientations = ["Elevación"];
const ElectroconductivityAmbientations = ["Conductividad eléctrica"];
const ElectroAmbientations = ["Electroconductividad a 90cm", "Electroconductividad a 30cm", "Superficial", "En profundidad (150cm)"];
const OrderOfAmbientationLayersInReport = [
    { id: 1, name: "Materia Orgánica" },
    { id: 2, name: "pH" },
    { id: 3, name: "Capacidad Intercambio Catiónico" },
    { id: 4, name: "Calcio" },
    { id: 5, name: "Magnesio" },
    { id: 6, name: "Ca-Mg" },
    { id: 7, name: "Sodio" },
    { id: 8, name: "Potasio" },
    { id: 9, name: "Fósforo" },
    { id: 10, name: "Azufre" },
    { id: 11, name: "Zinc" },
    { id: 12, name: "Boro" },
    { id: 13, name: "Manganeso" },
    { id: 14, name: "Cobre" },
    { id: 15, name: "Hierro" },
    { id: 16, name: "Cobalto" },
    { id: 17, name: "Molibdeno" },
];

const UserRoleEnum = {
    ADMIN: 99,
    PRODUCER: 1,
};

export {
    months,
    AmbientationFilesType,
    cerealType,
    ambientationsMapZoomRanges,
    squareThresholdAmbientationMap,
    prescriptionType,
    prescriptionTypeDict,
    RANGES_TYPES_OF_AMBIENTATIONS,
    LAYERS_AMBIENTATION_ERROR_MESSAGES,
    satelitalImagesType,
    SoilTypes,
    GetSoilTypes,
    TextureAmbientations,
    ElectroAmbientations,
    GetSoilDominant,
    GetSoilSubordinates,
    OrderOfAmbientationLayersInReport,
    GetPrescriptionTypeOptions,
    GetCropTypeOptions,
    AltitudeAmbientations,
    ElectroconductivityAmbientations,
    UserRoleEnum,
    GetUnitsForFertilizacion,
    GetUnitsForSiembra,
};
