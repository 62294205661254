/* eslint-disable camelcase */
import { RecommendationReportsServices } from "@/services/recommendation-reports-services";
import { router } from "@/router/NewRouter";
import { NotifyError } from "@/helpers/vue-notifications.helper";

const GetDefaultState = () => {
    return {
        ambientationMapInfo: null,
    };
};

const GetTotalHa = (ambients_info) => {
    return ambients_info.reduce((total, amb) => total + amb.ha, 0);
};

export default {
    namespaced: true,
    state: GetDefaultState(),
    getters: {
        getAmbientationMapInfo(state) {
            return state.ambientationMapInfo;
        },
        getColors(state) {
            return state.ambientationMapInfo.ambients_info.map((amb) => amb.color);
        },
        getTitles(state) {
            return state.ambientationMapInfo.ambients_info.map((amb) => amb.title);
        },
        getGeoImage(state) {
            return state.ambientationMapInfo.geo_image;
        },
        getTotalHa(state) {
            return GetTotalHa(state.ambientationMapInfo.ambients_info);
        },
        getAverageYieldByHa(state) {
            const totalHa = GetTotalHa(state.ambientationMapInfo.ambients_info);
            const totalYieldsByKg = state.ambientationMapInfo.ambients_info.reduce((total, amb) => total + Number(amb.yield_potential * amb.ha), 0);
            return Math.round(totalYieldsByKg / totalHa, 2);
        },
    },
    mutations: {
        ResetState(state) {
            Object.assign(state, GetDefaultState());
        },
        SetAmbientationMapInfo(state, data) {
            state.ambientationMapInfo = data;
        },
        SaveNewData(state, { final_recommendations, nutritional_plan_by_nutrient, ambients_info }) {
            if (final_recommendations) state.ambientationMapInfo.final_recommendations = final_recommendations;

            if (nutritional_plan_by_nutrient) state.ambientationMapInfo.nutritional_plan_by_nutrient = nutritional_plan_by_nutrient;

            if (ambients_info) state.ambientationMapInfo.ambients_info = ambients_info;
        }
    },
    actions: {
        ResetState({ commit }) {
            commit("ResetState");
        },
        async SetReportInfoFromServerAsync({ commit, dispatch }) {
            dispatch("processingDialogStore/showProcessing", null, { root: true });
            const reportId = router.currentRoute.params.reportId;
            const apiResult = await RecommendationReportsServices.GetRecommendationReportAsync(reportId);
            dispatch("processingDialogStore/hideProcessing", null, { root: true });

            if (!apiResult) return NotifyError("Error al obtener el informe de recomendación");

            apiResult.ambients_info.sort((a, b) => b.yield_potential - a.yield_potential);
            apiResult.nutritional_plan_by_nutrient.sort((a, b) => b.value - a.value);
            commit("SetAmbientationMapInfo", apiResult);
        },
        async SaveReportInfoAsync({ commit, dispatch, state }, { campaign, final_recommendations, nutritional_plan_by_nutrient, ambients_info }) {
            dispatch("processingDialogStore/showProcessing", null, { root: true });
            const reportId = router.currentRoute.params.reportId;

            // Nos aseguramos de que si algún dato viene null, lo reemplazamos por el valor actual
            if (campaign == null) campaign = state.ambientationMapInfo.campaign;
            if (final_recommendations == null) final_recommendations = state.ambientationMapInfo.final_recommendations;
            if (nutritional_plan_by_nutrient == null) nutritional_plan_by_nutrient = state.ambientationMapInfo.nutritional_plan_by_nutrient;
            if (ambients_info == null) ambients_info = state.ambientationMapInfo.ambients_info;

            const apiResult = await RecommendationReportsServices.SaveRecommendationReportAsync(reportId, final_recommendations, nutritional_plan_by_nutrient, ambients_info, campaign);

            dispatch("processingDialogStore/hideProcessing", null, { root: true });

            if (!apiResult) return NotifyError("Error al guardar el informe de recomendación");

            commit("SaveNewData", { final_recommendations, nutritional_plan_by_nutrient, ambients_info });

            return apiResult;
        },
    },
};
