import UserPreferences from "../../config/user-preferences.config";

export default {
    namespaced: true,
    state: {
        mapOpacity: UserPreferences.getMapOpacity(),
    },
    getters: {
    },
    mutations: {
        setOpacity(state, value) {
            state.mapOpacity = value;
            UserPreferences.setMapOpacity(value);
        },
    },
    actions: {
        setOpacity({ commit }, value) {
            commit("setOpacity", value);
        },
    },
};
