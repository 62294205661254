<template>
    <v-row no-gutters class="d-flex align-start cateogories-special-panel">
        <v-expansion-panels v-model="panelCategoriesRanges" multiple v-if="canCategorize">
            <v-expansion-panel class="expansionPanel">
                <v-expansion-panel-header class="text-center poppins-font titleMP" :expand-icon="mdiChevronDown">
                    <v-row no-gutters>
                        <v-col v-if="!showHighlightedCategoryAsTitle" cols="12"
                            class="d-flex align-center justify-center">
                            <strong>Referencias</strong>
                        </v-col>
                        <v-col v-if="!showHighlightedCategoryAsTitle" cols="12" class="subtitle-2">{{
                            getCategoriesUnitTitle }}</v-col>

                        <v-col v-if="showHighlightedCategoryAsTitle" cols="12">
                            <strong>Categoría: {{ getHighlightCategory }}</strong>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-justify px-0">
                    <v-row no-gutters class="">
                        <v-col cols="12" class="mb-2 mt-2 text-subtitle-1 b-1" v-if="getCategories.length > 0">
                            <v-row no-gutters v-for="(category, index) in getCategories" :key="index"
                                :class="{ 'highlighted-row': category.highlightCategory }" class="hover-gray">
                                <v-col cols="7" class="d-flex align-center px-2 b-1">
                                    {{ category.name }}
                                </v-col>
                                <v-col cols="5" class="d-flex align-center px-2 b-1 justify-end">
                                    {{ GetRangeString(category) }}
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="showMethodology">
                        <v-col cols="12" class="text-center poppins-font subtitle-2">
                            {{ getMethodology }}
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-row>
</template>

<script>
import { mdiChevronDown } from "@mdi/js";
export default {
    props: {
        ambientation: {
            type: Object,
            require: true,
        },
        showMethodology: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Boolean,
            default: true,
        },
        showHighlightedCategoryAsTitle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            panelCategoriesRanges: [this.expanded ? 0 : 1],
            mdiChevronDown,
        };
    },
    methods: {
        GetRangeString(category) {
            if (category.rangeFrom != null && category.rangeTo != null) return `${category.rangeFrom} a ${category.rangeTo}`;

            if (category.rangeFrom == null && category.rangeTo != null) return `menor a ${category.rangeTo}`;

            if (category.rangeFrom != null && category.rangeTo == null) return `mayor a ${category.rangeFrom}`;
        },
    },
    computed: {
        canCategorize() {
            return this.ambientation?.categoriesInfo.length > 0;
        },
        getCategories() {
            if (!this.ambientation) return [];

            return this.ambientation.categoriesInfo.slice().sort((a, b) => {
                if (a.rangeTo === null) return 1;
                if (b.rangeTo === null) return -1;
                if (a.rangeFrom !== b.rangeFrom) return a.rangeFrom - b.rangeFrom;
                return a.rangeTo - b.rangeTo;
            });
        },
        getCategoriesUnitTitle() {
            if (this.ambientation == null) return "";

            if (this.ambientation.layers.length == 1) {
                const unitText = this.ambientation.unit != null ? `(${this.ambientation.unit})` : "";
                return `${this.ambientation.layers[0].shortName} ${unitText}`;
            }

            return "";
        },
        getMethodology() {
            if (this.ambientation == null) return "";

            if (this.ambientation.layers.length == 1) return this.ambientation.layers[0].methodology;

            return "";
        },
        getHighlightCategory() {
            if (this.ambientation == null) return "";

            const highlightCategory = this.ambientation.categoriesInfo.find((category) => category.highlightCategory);

            return highlightCategory?.name ?? "";
        },
    },
};
</script>

<style lang="scss" scoped>
.color-picker-1 {
    width: 28px;
    min-width: 28px;
    height: 28px;
    padding: 0px;
    margin: 0;
    border: none;
}
</style>

<style>
.cateogories-special-panel .v-expansion-panel-content__wrap {
    padding: 5px !important;
}

.highlighted-row {
    background-color: rgb(187, 185, 185);
}
</style>
