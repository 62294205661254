<template>
    <section class="p-relative">
        <v-btn v-if="!isProducerView && allowEdition && !isEditing && getVisibilityEditionButtons"
            class="color-primary btn" small fab @click.prevent="ToggleToEditMode">
            <svg-icon type="mdi" :path="mdiPencilOutline"></svg-icon>
        </v-btn>

        <span v-if="isEditing" class="btn">
            <v-btn color="default" @click="ResetClick()" small fab>
                <my-tooltip text="Escape para cancelar" :custom-styles="{ fontSize: '1.4rem' }">
                    <svg-icon type="mdi" :path="mdiWindowClose"></svg-icon>
                </my-tooltip>
            </v-btn>
            <v-btn color="primary" small fab class="ml-2" :disabled="disabledUpdateButton" @click="UpdateValueClick()">
                <my-tooltip text="CTRL+ENTER para guardar" :custom-styles="{ fontSize: '1.4rem' }">
                    <svg-icon type="mdi" :path="mdiCheckBold"></svg-icon>
                </my-tooltip>
            </v-btn>
        </span>

        <ul v-if="!isEditing" class="my-ul">
            <li v-for="(item, index) in getItems" :key="index">
                {{ item }}
            </li>
        </ul>

        <div v-if="isEditing">
            <v-textarea :placeholder="getPlaceholderForTextareas" v-model="newValue" :rows="rows" no-resize
                @keydown="HandleKeydown" ref="editableArea"></v-textarea>
        </div>
    </section>
</template>

<script>
import { mdiPencilOutline, mdiWindowClose, mdiCheckBold } from "@mdi/js";
import { mapGetters } from "vuex";
export default {
    props: {
        value: {
            type: String,
            required: true,
        },
        rows: {
            type: Number,
            default: 6,
        },
        allowEdition: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isEditing: false,
            newValue: "",
            mdiPencilOutline, mdiWindowClose, mdiCheckBold,
        };
    },
    methods: {
        ToggleToEditMode() {
            this.newValue = this.value;
            this.isEditing = true;

            this.$nextTick(() => {
                this.$refs.editableArea.focus();
            });
        },
        ResetClick() {
            this.newValue = this.value;
            this.isEditing = false;
        },
        async UpdateValueClick() {
            this.isEditing = false;
            if (this.newValue == this.value) return;

            this.$emit("on-change", this.newValue);
        },
        HandleKeydown(event) {
            if (!this.isEditing) return;

            if (event.ctrlKey && event.key == "Enter") {
                event.preventDefault();
                return this.UpdateValueClick();
            }

            if (event.key == "Escape") {
                event.preventDefault();
                return this.ResetClick();
            }
        },
    },
    computed: {
        ...mapGetters("generalsStore", ["getVisibilityEditionButtons"]),
        ...mapGetters("userStore", ["isProducerView"]),
        disabledUpdateButton() {
            return this.newValue == this.value;
        },
        getItems() {
            return this.value.split("*").map((item) => item.trim()).filter((item) => item);
        },
        getPlaceholderForTextareas() {
            return "Recuerda usar asteriscos para que luego se vea como una lista. Por ejemplo: \n* Item 1... \n* Item 2... \n* Item 3...";
        },
    },
};
</script>

<style lang="scss" scoped>
.btn {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.my-ul {
    min-height: 100px;
}
</style>
