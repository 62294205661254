import { render, staticRenderFns } from "./ToogleButtonBetweenRoles.vue?vue&type=template&id=5fdd73ae&scoped=true"
import script from "./ToogleButtonBetweenRoles.vue?vue&type=script&lang=js"
export * from "./ToogleButtonBetweenRoles.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fdd73ae",
  null
  
)

export default component.exports