<template>
    <div class="my-selector-container" :class="{ 'container-focused': optionsVisibility, 'compacted': compacted }"
        @click.capture="ToogleOptions">

        <div class="chip-container">
            <span v-for="chip in GetChips()" class="chip" :key="chip.name">{{ chip.name }}</span>
        </div>

        <my-tooltip text="Limpiar filtros!" v-if="showUncheckAllOption">
            <div @click="TurnAllOptionsToUnchecked" class="clear-chips">
                <svg-icon type="mdi" :path="mdiCloseCircleOutline" size="30"></svg-icon>
            </div>
        </my-tooltip>

        <span :class="{ 'arrow-icon-opened': optionsVisibility }" class="arrow-icon">▼</span>

        <div :class="{ 'options-container-visible': optionsVisibility }" class="options-container">
            <v-input class="option-label" v-if="showSelectAllOption" @click="TurnAllOptionsToChecked">Seleccionar
                todo</v-input>
            <div class="option-label" @click="EmitEventListChange(option)" v-for="option in myOptions"
                :key="option.name">
                <v-input class="" v-model="option.checked">
                    <div class="d-flex">
                        <check-box :checked="option.checked" class="mr-2" />
                        {{ option.name }}
                    </div>
                </v-input>
            </div>
        </div>

        <div class="placeholder " :class="{ 'active': isSomeOptionChecked }">
            {{ placeholder }}
        </div>
    </div>
</template>

<script>
import { mdiCloseCircleOutline } from "@mdi/js";

export default {
    props: {
        options: {
            type: Array,
            required: true,
        },
        limitOfSelectedItems: {
            type: Number,
            default: 1,
        },
        selectIndex: {
            type: Number,
            default: null
        },
        multiselect: {
            type: Boolean,
            default: false
        },
        compacted: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "Selecciona una opción"
        },
    },
    data() {
        return {
            optionsVisibility: false,
            myOptions: [],
            mdiCloseCircleOutline
        };
    },
    mounted() {
        this.myOptions = JSON.parse(JSON.stringify(this.options));
        document.addEventListener("click", this.handleDocumentClick);
    },

    beforeDestroy() {
        document.removeEventListener("click", this.handleDocumentClick);
    },
    methods: {
        ToogleOptions() {
            this.optionsVisibility = !this.optionsVisibility;
            this.$emit("show-options");
        },
        ShowOptions() {
            this.optionsVisibility = true;
        },
        HideOptions() {
            this.optionsVisibility = false;
        },
        GetChips() {
            const selectItem = this.myOptions.filter(x => x.checked);
            return selectItem;
        },
        TurnAllOptionsToChecked() {
            this.myOptions.forEach(option => (option.checked = true));
            this.$emit("list-change", this.myOptions);
            this.HideOptions();
        },
        TurnAllOptionsToUnchecked() {
            this.myOptions.forEach(option => (option.checked = false));
            this.$emit("list-change", this.myOptions);
            this.HideOptions();
        },
        EmitEventListChange(option) {
            if (!this.multiselect) {
                this.myOptions.forEach(x => (x.checked = false));
                option.checked = true;
            }
            if (this.multiselect) option.checked = !option.checked;

            this.$emit("list-change", this.myOptions);
            this.HideOptions();
        },
        handleDocumentClick(event) {
            const container = this.$el;
            if (container && !container.contains(event.target)) {
                this.optionsVisibility = false;
            }

        },
    },
    computed: {
        showSelectAllOption() {
            if (!this.multiselect) return false;

            const checkedOptions = this.myOptions.filter(option => option.checked);
            return checkedOptions.length != this.myOptions.length;
        },
        showUncheckAllOption() {
            if (!this.multiselect) return false;

            const checkedOptions = this.myOptions.filter(option => option.checked);
            return checkedOptions.length > 0;
        },
        isSomeOptionChecked() {
            return this.myOptions.some(option => option.checked);
        },
    },
    watch: {
        options: {
            handler() {
                this.myOptions = JSON.parse(JSON.stringify(this.options));
            },
            deep: true,
        },
    }
};
</script>

<style lang="scss">
.my-selector-container {
    padding-left: 0px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    height: 50px;
    padding: 10px 10px 10px 0px;
    display: flex;
    position: relative;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    text-align: start;

    .v-selection-control--density-comfortable {
        --v-selection-control-size: auto;
    }

    .v-selection-control__wrapper {
        padding: 15px 5px 15px 20px;
    }

    .v-selection-control--inline .v-label {
        padding: 15px 20px 15px 5px;
    }

    .v-checkbox.v-input,
    .v-switch.v-input {
        display: block;
    }

    .v-messages {
        min-height: 0px;
    }

    .v-input__slot {
        margin: 0px;
        height: 40px;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        margin-top: 15px;
    }

    .v-checkbox {
        width: 100%;
        margin-inline-start: auto;
    }

    &-focused {
        border: 2px solid black;
    }

    .chip-container {
        width: calc(100% - 60px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: start;
        padding: 0px 5px;
        min-width: 200px;

        .chip {
            background-color: #E0E0E0;
            border-radius: 15px;
            line-height: 1.8rem;
            padding: 0 10px;
            margin-right: 5px;
            font-size: 0.8rem;
            max-width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: table-cell;
        }
    }

    .arrow-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        font-size: 1.3rem;
        transition: all 0.2s;
        transform: translate(0, -50%) scaleY(0.6);
        opacity: 0.7;

        &-opened {
            transform: translate(0, -50%) scaleY(-0.6);
        }
    }

    .label {
        position: absolute;
        transition: all 0.3s;
        top: 50%;
        padding: 2px 12px;
        font-size: 16px;
        transform: translate(0, -50%);
        color: rgba(0, 0, 0, 0.63);

        &-toped {
            top: -20%;
            font-size: 0.75rem;
            padding: 5px 10px;
        }

    }

    .options-container {
        padding: 0px;
        min-width: 100%;
        overflow-y: scroll;
        position: absolute;
        top: 50px;
        z-index: 9;
        background: white;
        box-shadow: 0 0 5px rgb(212, 209, 209);
        transition: all 0.2s;
        max-height: 0;
        max-width: 800px;
        right: 0px;

        &-visible {
            max-height: 400px;
        }
    }

    .close-icon {
        display: block;
        opacity: 0.6;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 30px;
        background-color: #FFFFFF30;
        border-radius: 50%;
        padding: 5px;
    }

    .option-label {
        margin: 0px;
        width: 100%;
        padding-left: 10px;

        &:hover {
            background: #E0E0E0;
        }
    }

    .clear-chips {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 40px;
        opacity: 0.4;
        cursor: pointer;
        display: flex;
        width: 30px;
        height: 30px;
    }
}

.compacted {
    height: 30px;
    margin-top: 10px;
    padding: 0px;

    .label {
        font-size: 0.8rem;

        &-toped {
            top: -10px;
        }
    }

    .options-container {
        top: 30px;
    }

    .v-input__slot {
        margin-top: 5px;
        height: 30px;
    }

    .option-label {
        font-size: 0.9rem !important;
    }

    .arrow-icon {
        font-size: 1rem;
    }
}

.placeholder {
    position: absolute;
    color: #000000A0;
    left: 10px;
    top: 50%;
    transition: all 0.3s;
    transform: translate(0, -50%);
    font-size: 1rem;

    &.active {
        top: -24px;
        font-size: 0.75rem;
        padding: 5px 0px;
        transform: translate(0, 0);
    }
}
</style>
