<template>
    <v-container>
        <v-row class="mt-5" v-if="userPosition">
            <h5>Geolocation - POC</h5>
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <label>position: {{ userPosition }}</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters class="p-relative mt-5">
            <div :id="`geo-${_uid}`" class="map-styles" :style="getStyles"></div>
            <div class="slider-map">
                <v-slider v-model="myMapOpacity" :prepend-icon="mdiCircleOpacity" :dark="true" color="white"
                    thumb-color="white" min="0" max="1" step="0.1"></v-slider>
            </div>
        </v-row>
    </v-container>
</template>

<script>
import { GetUSGSOverlay, GetDefaultGmapSettings } from "../../services/google.services.js";
import { mapState, mapActions, mapGetters } from "vuex";
import { mdiCircleOpacity, mdiCrosshairsGps } from "@mdi/js";
import { NotifyError } from "../../helpers/vue-notifications.helper.js";

export default {
    props: {
        geoImage: {
            type: Object,
            require: true
        },
        height: {
            type: String,
            default: "70vh"
        },
        customMapZoom: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            map: null,
            overlay: null,
            mdiCircleOpacity,
            mdiCrosshairsGps,
            myMapOpacity: 0,
            geolocationMarker: null,
            userPosition: null
        };
    },
    async mounted() {
        this.ShowProcessingDialog();
        const position = await this.getCurrentPosition();
        this.userPosition = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        };
        this.watchUserLocation();
        await this.InitMapAsync();
        await this.HideProcessingDialog();
    },
    beforeDestroy() {
        this.ClearWatchInterval();
    },
    computed: {
        ...mapState("userPreferences", ["mapOpacity"]),
        ...mapGetters("satelitalMapStore", ["getGeolocationEnablement"]),
        getStyles() {
            const result = {};

            if (this.height) result.height = this.height;

            return result;
        }
    },
    methods: {
        ...mapActions("userPreferences", ["setOpacity"]),
        ...mapActions("satelitalMapStore", ["setGeolocationEnablement"]),
        async getCurrentPosition() {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject, {
                    enableHighAccuracy: true
                });
            });
        },
        watchUserLocation() {
            this.intervalId = setInterval(async () => {
                try {
                    const position = await this.getCurrentPosition();
                    this.userPosition = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    };
                } catch (error) {
                    NotifyError("Error al obtener la posición del usuario.");
                }
            }, 500);
        },
        ClearWatchInterval() {
            clearInterval(this.intervalId);
        },
        async InitMapAsync() {
            const ambientationMapZoom = 19;

            const ambientationMap = document.getElementById(`geo-${this._uid}`);
            const options = GetDefaultGmapSettings();

            options.center = { lat: this.userPosition.latitude, lng: this.userPosition.longitude };
            options.zoom = ambientationMapZoom;

            // eslint-disable-next-line no-undef
            const { Map } = await google.maps.importLibrary("maps");
            this.map = new Map(ambientationMap, options);
        },
        SetOverlay(bounds, srcImage) {
            this.overlay = GetUSGSOverlay(bounds, srcImage, this.mapOpacity);
            this.overlay.setMap(this.map, { disableAutoPan: true });
        },
        DeleteMapOverlay() {
            this.overlay.setMap(null);
        }
    },
    watch: {
        myMapOpacity(newVal) {
            this.setOpacity(newVal);
        },
        mapOpacity(newVal) {
            this.myMapOpacity = newVal;
            if (this.overlay) this.overlay.setOpacity(newVal);
        },
        userPosition(newVal) {
            if (this.userPosition && this.map) {
                // eslint-disable-next-line no-undef
                const newPosition = new google.maps.LatLng(newVal.latitude, newVal.longitude);
                this.map.setCenter(newPosition);

                if (!this.geolocationMarker) {
                    // eslint-disable-next-line no-undef
                    this.geolocationMarker = new google.maps.Marker({
                        position: newPosition,
                        map: this.map,
                        icon: {
                            path: mdiCrosshairsGps,
                            scale: 1,
                            fillColor: "blue",
                            fillOpacity: 1,
                            strokeWeight: 0
                        }
                    });
                }

                if (this.geolocationMarker) {
                    this.geolocationMarker.setPosition(newPosition);
                }
            }
        },
    }
};
</script>

<style scoped lang="scss">
#ambientationMap {
    height: 600px;
}

.map-styles {
    height: 500px;
    width: 100%;
}

.slider-map {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 35%;
}
</style>
