class PwaSettings { };

PwaSettings.InitializeAsync = async () => {
    console.log(process.env.NODE_ENV);

    if (process.env.NODE_ENV == "development") return console.log("🚫 Service Worker no se instala en modo de desarrollo");

    await InitializeServiceWorkerAsync();
};

function InvokeServiceWorkerUpdateFlow(registration) {
    // Verificar si el service worker en estado "waiting" está disponible
    if (registration.waiting) {
        console.log("Nueva versión disponible. Activando la actualización automáticamente...");
        // Enviar el mensaje al service worker esperando para que se active inmediatamente
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
}

const InitializeServiceWorkerAsync = async () => {
    // Verificar si el navegador soporta service workers
    if ("serviceWorker" in navigator) {
        // Esperar a que la página cargue completamente
        window.addEventListener("load", async () => {
            try {
                // Registrar el service worker
                const registration = await navigator.serviceWorker.register("/service-worker.js");

                // Comprobar si ya hay un SW en estado 'waiting' y si es así, invocar el flujo de actualización
                if (registration.waiting) InvokeServiceWorkerUpdateFlow(registration);

                // Detectar si hay una actualización disponible para el SW
                registration.addEventListener("updatefound", () => {
                    const newWorker = registration.installing;
                    if (newWorker) {
                        // Escuchar el cambio de estado del nuevo SW
                        newWorker.addEventListener("statechange", () => {
                            if (newWorker.state === "installed") {
                                // Comprobar si hay un SW previo que necesita ser reemplazado
                                if (registration.waiting && navigator.serviceWorker.controller) {
                                    InvokeServiceWorkerUpdateFlow(registration);
                                } else if (!navigator.serviceWorker.controller) {
                                    // Primera instalación del SW, no es necesario hacer nada
                                    console.log("Service Worker instalado por primera vez");
                                }
                            }
                        });
                    }
                });

                // Forzar la verificación de actualizaciones periódicas (ejemplo: cada 60 segundos)
                setInterval(() => {
                    console.log("🔄 Verificando actualizaciones...");
                    registration.update(); // Forzar la verificación de una nueva versión del SW
                }, 60000); // 60 segundos, ajusta este valor según tus necesidades

                // Detectar cuando cambia el controlador (nuevo SW activo) y recargar la página
                let refreshing = false;
                navigator.serviceWorker.addEventListener("controllerchange", () => {
                    if (!refreshing) {
                        window.location.reload(); // Recargar la página cuando se activa el nuevo SW
                        refreshing = true;
                    }
                });
            } catch (error) {
                console.error("Error al registrar el Service Worker:", error);
            }
        });
    } else {
        console.log("El navegador no soporta Service Workers.");
    }
};

export default PwaSettings;
