import PrescriptionServices from "@/services/prescription.services";
import { router } from "@/router/NewRouter";
import { NotifyError, NotifyOk } from "@/helpers/vue-notifications.helper";

export default {
    namespaced: true,
    state: {
        ambientationsInfo: null
    },
    getters: {
        getPrescriptionInfo(state) {
            if (state.ambientationsInfo == null) return null;

            return state.ambientationsInfo;
        },
    },
    mutations: {
        setPrescriptionAmbientationsInfo(state, value) {
            if (value == null) return;

            state.ambientationsInfo = value;
        }
    },
    actions: {
        async setPrescriptionAmbientationsInfo({ dispatch, commit }) {
            dispatch("processingDialogStore/showProcessing", null, { root: true });

            const toolFieldId = router.currentRoute.params.prescriptionId;
            const processId = Number(router.currentRoute.params.processId);
            const data = await PrescriptionServices.GetPrescriptionFullProcessInfoAsync(toolFieldId, processId);

            dispatch("processingDialogStore/hideProcessing", null, { root: true });

            if (!data) return;

            commit("setPrescriptionAmbientationsInfo", data);
        },
        async UpdateDescriptionAsync({ dispatch }, newDescription) {
            dispatch("processingDialogStore/showProcessing", null, { root: true });
            const processId = Number(router.currentRoute.params.processId);

            const newPrescriptionInfo = await PrescriptionServices.UpdatePrescriptionAsync(processId, newDescription);

            dispatch("processingDialogStore/hideProcessing", null, { root: true });

            if (newPrescriptionInfo) {
                dispatch("setPrescriptionAmbientationsInfo");
                return NotifyOk("La descripción ha sido actualizada");
            }

            NotifyError("Algo ha salido mal, intente más tarde");
        }
    }
};
