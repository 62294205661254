import { router } from "../router/NewRouter";

const logout = (urlToRedirectWhenLogin) => {
    if (urlToRedirectWhenLogin == "/login") return;

    const route = { path: "/login" };
    const ignoredQuerys = [null, "/home"];
    if (ignoredQuerys.includes(urlToRedirectWhenLogin) == false) route.query = { to: urlToRedirectWhenLogin };

    router.push(route).catch((error) => {
        console.log(error);
    });
};

export {
    logout
};
