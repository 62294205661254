<template>
    <div v-if="showActivityBar" class="activity-bar justify-space-around" :class="{ 'hidden': isHidden }">

        <!--<button @click="goToNotifications">
            <i class="fas fa-bell"></i>
        </button> -->
        <i class="fas fa-arrow-left my-left-arrow" @click="GoBack"></i>

        <router-link v-if="user != null"
            :to="userAdmin ? { name: 'Producers' } : { name: 'Fields', params: { producerId: user.ProducerId } }">
            <i class="fas" :class="{
                'fa-map-marked-alt': !userAdmin,
                'fa-users': userAdmin,
                'scaled': producerIconScaled
            }"></i>
        </router-link>

        <router-link :to="{ name: 'Home' }">
            <i class="fas fa-home" :class="{ 'scaled': homeIconScaled }"></i>
        </router-link>

        <button @click="CopyAndShowSharedPage">
            <i class="fas fa-share-alt"></i>
        </button>

        <button @click="SetMenuVisibility(!isMenuVisible)" v-if="user">
            <i class="fas fa-bars"></i>
        </button>

        <my-go-top-button />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { OkMessageAsync } from "@/helpers/sweet-alerts.helper";
export default {
    data() {
        return {
            isHidden: false,
            lastScrollY: 0
        };
    },
    mounted() {
        // window.addEventListener("scroll", this.HandleScroll);
    },
    beforeDestroy() {
        // window.removeEventListener("scroll", this.HandleScroll);
    },
    computed: {
        ...mapGetters("guiStore", ["isMenuVisible", "showActivityBar"]),
        homeIconScaled() {
            return this.$route.name === "Home" ? "scaled" : "";
        },
        producerIconScaled() {
            const pathNames = ["Producers", "Fields"];
            return pathNames.includes(this.$route.name) ? "scaled" : "";

        },
    },
    methods: {
        ...mapActions("guiStore", ["SetMenuVisibility"]),
        CopyAndShowSharedPage() {
            const url = window.location.href;
            navigator.clipboard.writeText(url);
            const html = `
                <a href="${url}" target="_blank">
                    <b>${url}</b>
                </a>
                <br/><br/>
                El link ya está listo para que lo pegues donde quieras!
            `;
            OkMessageAsync("Sección copiada!", "", html);
        },
        GoBack() {
            this.$router.go(-1);
        },
    }
};
</script>

<style scoped lang="scss">
.activity-bar {
    align-items: center;
    height: 60px;
    z-index: 10;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background: linear-gradient(to bottom, rgba($theme-color, 0.9), rgba($theme-color, 0.9));
    backdrop-filter: blur(20px);
    border-top: 1px solid #000;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
    transition: transform 0.2s ease-in-out;

    &.hidden {
        transform: translateY(80%);
    }

    @media (min-width: 800px) {
        display: none;
    }

    button,
    a {
        background: none;
        border: none;
        font-size: 24px;
        color: #ffffff;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        &:focus {
            outline: none;
        }
    }
}

.activity-bar button:focus {
    outline: none;
}

.fas {
    transition: all 0.2s ease-in-out;
}

.scaled {
    transform: scale(1.5);
    filter: drop-shadow(0px 0px 3px #ffffff90);
}

.my-left-arrow {
    transform: scale(1.4);
    color: #ffffff;
}
</style>
