<template>
    <!-- <my-tooltip v-if="userAdmin" :text="getTooltipTitle"> -->
    <v-btn v-if="userAdmin" color="secondary" class="my-toogle-button mr-2" @click="SetProducerView(!isProducerView)">
        <svg-icon type="mdi" :path="mdiAccountConvert" size="28"></svg-icon>
        <span class="ml-2">{{ getTitle }}</span>
    </v-btn>
    <!-- </my-tooltip> -->
</template>

<script>
import { mdiAccountConvert } from "@mdi/js";
import { mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            mdiAccountConvert,
        };
    },
    computed: {
        ...mapGetters("userStore", ["isProducerView"]),
        getTitle() {
            if (this.isProducerView) return "Vista productor";
            return "Vista admin";
        },
        getTooltipTitle() {
            return "Alternar vista entre productor y admin";
        }
    },
    methods: {
        ...mapActions("userStore", ["SetProducerView"]),
    }
};
</script>

<style scoped lang="scss"></style>
