
import Titulo from "../Titulo.vue";
import Descripcion from "../Descripcion.vue";
import BotonVerMapa from "../BotonVerMapa.vue";
import Spinner from "./Spinner";
import CheckBox from "./CheckBox";
import ShapesAgroideas from "../ShapesAgroideas";
import Separator from "./Separator.vue";
import MyAddButton from "./MyAddButton.vue";
import Instagram from "./Instagram.vue";
import Website from "./Website.vue";
import PWAButton from "./PWAButton.vue";
import BackButton from "./BackButton.vue";
import NewAvailableVersion from "./NewAvailableVersion.vue";
import ProcessingDialog from "./ProcessingDialog.vue";
import MySelect from "./MySelect.vue";
import MyAutocompleter from "./MyAutocompleter.vue";
import MyDatePicker from "./MyDatePicker.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import MyTooltip from "./MyTooltip.vue";
import Breadcrumb from "./bread-crumb/Breadcrumb.vue";
import GoTopButton from "./GoTopButton.vue";
import SatelitalMap from "./SatelitalMap.vue";
import References from "./References.vue";
import POCGeolocaiton from "./POCGeolocation.vue";
import DistributedLineGraph from "./DistributedLineGraph.vue";
import NoConnectionModal from "./NoConnectionModal.vue";
import MyHamburgerMenu from "./MyHamburgerMenu.vue";
import AmbientationCategoriesPanel from "@/views/Secciones/Ambientations/section-3/components/AmbientationCategoriesPanel.vue";
import VerticalChart from "@/views/shared-components/VerticalChart.vue";
import AmbientationMapCard from "./AmbientationMapCard.vue";
import VueApexCharts from "vue-apexcharts";
import ListItemsEditable from "./ListItemsEditable.vue";
import TextEditable from "./TextEditable.vue";
import MyCard from "./MyCard.vue";
import ToogleButtonBetweenRoles from "./ToogleButtonBetweenRoles.vue";

export async function LoadGlobalComponents(Vue) {
    Vue.component("Titulo", Titulo);
    Vue.component("Descripcion", Descripcion);
    Vue.component("BotonVerMapa", BotonVerMapa);
    Vue.component("Spinner", Spinner);
    Vue.component("check-box", CheckBox);
    Vue.component("ShapesAgroideas", ShapesAgroideas);
    Vue.component("Separator", Separator);
    Vue.component("my-add-button", MyAddButton);
    Vue.component("Instagram", Instagram);
    Vue.component("Website", Website);
    Vue.component("pwa-button", PWAButton);
    Vue.component("my-back-button", BackButton);
    Vue.component("new-available-version", NewAvailableVersion);
    Vue.component("processing-dialog", ProcessingDialog);
    Vue.component("my-select", MySelect);
    Vue.component("my-autocompleter", MyAutocompleter);
    Vue.component("my-date-picker", MyDatePicker);
    Vue.component("SvgIcon", SvgIcon);
    Vue.component("my-tooltip", MyTooltip);
    Vue.component("bread-crumb", Breadcrumb);
    Vue.component("my-go-top-button", GoTopButton);
    Vue.component("satelital-map", SatelitalMap);
    Vue.component("references", References);
    Vue.component("poc-geolocation", POCGeolocaiton);
    Vue.component("distributed-line-graph", DistributedLineGraph);
    Vue.component("no-connection-modal", NoConnectionModal);
    Vue.component("my-hamburger-menu", MyHamburgerMenu);
    Vue.component("ambientation-categories-panel", AmbientationCategoriesPanel);
    Vue.component("vertical-chart", VerticalChart);
    Vue.component("ambientation-map-card", AmbientationMapCard);

    Vue.component("apexchart", VueApexCharts);
    Vue.component("list-items-editable", ListItemsEditable);
    Vue.component("text-editable", TextEditable);
    Vue.component("my-card", MyCard);
    Vue.component("toogle-button-between-roles", ToogleButtonBetweenRoles);
}
