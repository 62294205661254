<template>
    <div class="new-version" id="NewAvailableVersionDiv">
        <div class="mb-1 pb-1 title font-weight-bold">🚀 ¡Nueva actualización!</div>
        <div class="subtitle-1">Más funciones, más velocidad, más crecimiento 🌱</div>
        <div class="justify-space-between d-flex mt-4 subtitle-1">
            <span class="version-text">{{ getVersion }}</span>
            <v-btn small color="secondary" @click="HideModal()">Cerrar</v-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    mounted() {
        const currentVersion = localStorage.getItem("version");
        //TODO: Borrar luego de verificar que todo funcione correctamente
        console.log("currentVersion (LS)", currentVersion);
        console.log("this.getVersion", this.getVersion);
        if (currentVersion !== this.getVersion) {
            document.getElementById("NewAvailableVersionDiv").style.display = "block";
        }
    },
    methods: {
        HideModal() {
            document.getElementById("NewAvailableVersionDiv").style.display = "none";
            localStorage.setItem("version", this.getVersion);
        },
    },
    computed: {
        ...mapGetters("generalsStore", ["getVersion"]),
    },
};
</script>

<style lang="scss" scoped>
.new-version {
    position: fixed;
    display: none;
    bottom: 5px;
    right: 5px;
    z-index: 9999;
    color: #000;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
    font-size: 1.2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8) !important;
    width: 420px;
    max-width: calc(100% - 10px);
    cursor: pointer;
    background: #ffffff80;
    backdrop-filter: blur(10px);

    @media (max-width: 800px) {
        width: 100%;
        bottom: 5px;
    }
}
.version-text {
    font-size: 0.8rem;
}
</style>
