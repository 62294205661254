export const UpdateWebsocketCircle = (connected = false) => {
    const wsStatusCircle = document.getElementById("ws-status-circle");

    if (!wsStatusCircle) {
        const circleDiv = document.createElement("div");
        circleDiv.id = "ws-status-circle";
        document.body.appendChild(circleDiv);

        circleDiv.style.backgroundColor = !connected ? "red" : "green";
        return;
    }

    wsStatusCircle.style.backgroundColor = !connected ? "red" : "green";
};
