<template>
    <div v-if="chartOptions" class="w-100 d-flex align-center justify-center" :class="{ bordered: bordered }">
        <div class="bars-container align-center p-relative" :style="getBarsContainerStyles">
            <div class="y-axis-title">{{ chartOptions.yAxis.title }}</div>

            <div v-for="(serie, index) in chartOptions.serie" :key="index" class="bar-wrapper"
                :style="getBarWrapperStyles">
                <div class="bars-group" :style="getBarsGroupStyles">
                    <div class="bar-container">
                        <div class="bar gray-bar" :style="GrayBarStyles(serie.y)">
                            <div class="gray-bar-value">
                                <div>{{ GetPercentageOfHectares(serie.y) }}</div>
                                <div class="bolder" v-if="Math.round(serie.y) > 0">
                                    ({{ GetHectaresRounded(serie.y) }} ha)
                                </div>
                            </div>
                        </div>
                        <div class="bar colored" :style="ColoredBarStyles(serie.color)">
                            <div :style="TriangleStyles(serie.color)" class="triangle"></div>
                        </div>
                    </div>
                </div>

                <div class="x-axis">{{ serie.x }}</div>
                <div v-if="index == chartOptions.serie.length - 1" class="x-axis right-position">{{ serie.range.to }}
                </div>
            </div>

            <div class="x-axis-title" v-if="chartOptions.xAxis.title">{{ chartOptions.xAxis.title }}</div>
        </div>
    </div>
</template>

<script>
import { GetChartOptions } from "@/views/shared-components/VerticalChartUtils";
const MAX_WIDTH_OF_GROUP_BARS = 60;
const BARS_CONTAINER_HEIGHT_EXTRA = 120; // Usado para contemplar los valores de los números
const BARS_CONTAINER_HEIGHT = 60;

export default {
    props: {
        chartOptions: {
            type: Object,
            default: () => GetChartOptions(),
        },
        bordered: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            coloredBarsHeight: 10,
        };
    },
    methods: {
        TriangleStyles(color) {
            return {
                borderTop: `6px solid ${color}`,
            };
        },
        GrayBarStyles(topNumber) {
            const grayHeight = Math.round((topNumber / this.getMaxValueOfY) * (BARS_CONTAINER_HEIGHT - this.coloredBarsHeight));
            return {
                height: `${grayHeight}px`,
                top: `-${grayHeight}px`,
                position: "absolute",
                width: "100%",
            };
        },
        // Calcula la altura de la barra de color y la coloca desde la parte inferior
        ColoredBarStyles(color) {
            return {
                height: `${this.coloredBarsHeight}px`,
                backgroundColor: color,
                bottom: `-${this.coloredBarsHeight}px`,
                position: "absolute",
                width: "100%",
            };
        },
        GetPercentageOfHectares(hectares) {
            const percentage = Math.round((hectares / this.getTotalHectares) * 100);

            if (percentage === 0) return "";
            return `${percentage} %`;
        },
        GetHectaresRounded(hectares) {
            return Math.round(hectares);
        },
    },
    computed: {
        getBarsContainerStyles() {
            return {
                height: `${BARS_CONTAINER_HEIGHT + BARS_CONTAINER_HEIGHT_EXTRA}px`,
            };
        },
        getMaxValueOfY() {
            return Math.max(...this.chartOptions.serie.map((item) => item.y));
        },
        getBarWrapperStyles() {
            return {
                width: `calc(100% / ${this.chartOptions.serie.length})`,
                maxWidth: `${MAX_WIDTH_OF_GROUP_BARS}px`,
            };
        },
        getBarsGroupStyles() {
            return {
                maxWidth: `${MAX_WIDTH_OF_GROUP_BARS}px`,
            };
        },
        getTotalHectares() {
            return this.chartOptions.serie.reduce((acc, item) => acc + item.y, 0);
        },
    },
};
</script>

<style scoped lang="scss">
.bars-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 0;
    font-size: 1rem;
    width: 90%;
}

.bar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2px;
    width: 100%;
    position: relative;
}

.bars-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    padding: 0;
    margin: 0;
}

/* Contenedor relativo para que las barras se dibujen con posición absoluta */
.bar-container {
    position: relative;
    height: 0px;
    /* Altura máxima del contenedor */
    width: 100%;
}

.bar {
    position: absolute;
}

.gray-bar {
    background-color: #dddddd;
    /* border: 1px solid #00000030; */
    /* box-shadow: inset 0 0 10px #00000040; */
}

.colored {
    position: absolute;
    width: 100%;
}

.triangle {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 0;
    height: 0;
    border-right: 6px solid transparent;
}

.gray-bar-value {
    position: absolute;
    top: -35px;
    text-align: center;
    font-size: 0.9rem;
    left: 50%;
    width: 100px;
    transform: translateX(-50%);
    line-height: 15px;
}

.x-axis {
    text-align: center;
    left: -35%;
    top: 45px;
    position: absolute;
    transform: rotate(45deg);
    width: 100px;
    text-align: left;

    &.right-position {
        left: 65%;
    }
}

.x-axis-title,
.y-axis-title {
    position: absolute;
    top: 30px;
    left: 0;
    font-size: 1rem;
    text-align: center;
}

.x-axis-title {
    top: auto;
    bottom: 30px;
}

.bordered {
    border: 1px solid #00000020;
    padding: 5px;
    border-radius: 5px;
}
</style>
