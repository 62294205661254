<template>
    <v-row no-gutters class="p-relative h-100">
        <div :id="`mapa-${_uid}`" class="map-styles" :style="getStyles"></div>
        <div class="slider-map">
            <v-slider v-if="!hideControls" v-model="myMapOpacity" :prepend-icon="mdiCircleOpacity" :dark="true"
                color="white" thumb-color="white" min="0" max="1" step="0.1"></v-slider>
        </div>
    </v-row>
</template>

<script>
/* eslint-disable */
import { GetUSGSOverlay, GetDefaultGmapSettings } from "../../services/google.services.js";
import { mapState, mapActions, mapGetters } from "vuex";
import { mdiCircleOpacity, mdiCrosshairsGps } from "@mdi/js";
import { ambientationsMapZoomRanges } from "../../utils/general.utils";
import { NotifyError } from "../../helpers/vue-notifications.helper.js"

export default {
    props: {
        geoImage: {
            type: Object,
            require: true
        },
        height: {
            type: String,
            default: "30vh"
        },
        zoomGroupName: {
            type: String,
            required: false,
            default: "Default-Map-Zoom"
        },
        hideControls: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            map: null,
            overlay: null,
            mdiCircleOpacity,
            mdiCrosshairsGps,
            myMapOpacity: 0,
            geolocationMarker: null,
            watchIds: []
        }
    },
    async mounted() {
        this.myMapOpacity = this.mapOpacity;
        if (this.hideControls) this.myMapOpacity = 1;
        await this.InitMapAsync();
    },
    beforeDestroy() {
        this.ClearAllWatchIds();
        this.setGeolocationEnablement(false);
    },
    computed: {
        ...mapState("userPreferences", ["mapOpacity"]),
        ...mapGetters("satelitalMapStore", ["getGeolocationEnablement"]),
        getGeoImage() {
            if (this.geoImage) return this.geoImage;

            return null;
        },
        getSrcGeoImage() {
            return this.getGeoImage.src;
        },
        getStyles() {
            const result = {};

            if (this.height) result.height = this.height;

            return result;
        }
    },
    methods: {
        ...mapActions("userPreferences", ["setOpacity"]),
        ...mapActions("satelitalMapStore", ["setGeolocationEnablement"]),
        CalculateDynamicZoomForAmbientationsMap(imageWidth, imageHeight) {
            const aspectRatio = Math.abs(imageWidth / imageHeight);
            const zoomRange = ambientationsMapZoomRanges.find(range => aspectRatio <= range.maxRatio);

            const dynamicZoom = zoomRange ? zoomRange.zoom : 13;
            return dynamicZoom;
        },
        async watchUserLocation() {
            if (!("geolocation" in navigator)) {
                NotifyError("La geolocalización no es soportada por este navegador.");
                return;
            }

            const watchId = navigator.geolocation.watchPosition(position => {
                const { latitude, longitude } = position.coords;
                const userLatLng = new google.maps.LatLng(latitude, longitude);

                if (!this.map) return;

                this.map.setCenter(userLatLng);

                if (!this.geolocationMarker) {
                    this.geolocationMarker = new google.maps.Marker({
                        position: userLatLng,
                        map: this.map,
                        icon: {
                            path: mdiCrosshairsGps,
                            scale: 0.7,
                            fillColor: "blue",
                            fillOpacity: 1,
                            strokeWeight: 0
                        }
                    });
                }

                if (this.geolocationMarker) {
                    this.geolocationMarker.setPosition(userLatLng);
                }
            });

            this.watchIds.push(watchId);
        },
        ClearAllWatchIds() {
            this.watchIds.forEach(id => navigator.geolocation.clearWatch(id));
            this.watchIds = [];
            this.geolocationMarker = null;
        },
        async InitMapAsync() {
            if (this.getGeoImage == null) return;

            const bounds = this.GetBounds();

            const ambientationMap = document.getElementById(`mapa-${this._uid}`);
            if (!ambientationMap) return;

            const options = GetDefaultGmapSettings();
            if (this.hideControls) {
                options.disableDefaultUI = true;
                options.zoomControl = false;
                options.fullscreenControl = false; // Deshabilitar el control de pantalla completa
                options.mapTypeControl = false; // Deshabilitar el control de tipo de mapa
                options.streetViewControl = false; // Deshabilitar el control de Street View
                options.keyboardShortcuts = false;
                this.HideMapElements();
            }
            options.center = this.getGeoImage.location.center;

            // eslint-disable-next-line no-undef
            const { Map } = await google.maps.importLibrary("maps");
            this.map = new Map(ambientationMap, options);

            const srcImage = `${this.getSrcGeoImage}`;
            this.SetOverlay(bounds, srcImage);

            this.map.fitBounds(bounds);
        },
        SetOverlay(bounds, srcImage) {
            this.overlay = GetUSGSOverlay(bounds, srcImage, this.mapOpacity);
            this.overlay.setMap(this.map, { disableAutoPan: true });
        },
        GetBounds() {
            const result = new google.maps.LatLngBounds(
                new google.maps.LatLng(this.getGeoImage.location.SW.lat, this.getGeoImage.location.SW.lng),
                new google.maps.LatLng(this.getGeoImage.location.NE.lat, this.getGeoImage.location.NE.lng)
            );
            return result;
        },
        DeleteMapOverlay() {
            this.overlay.setMap(null);
        },
        HideMapElements() {
            const styleElement = document.createElement('style');
            styleElement.innerHTML = `
                .gmnoprint a, .gmnoprint span, .gm-style-cc {
                    display: none !important;
                }
                .gm-bundled-control {
                    display: none !important;
                }
                .gmnoprint div[draggable="true"] {
                    display: none !important;
                }
            `;
            document.head.appendChild(styleElement);
        }
    },
    watch: {
        ambientationInfo: {
            handler: 'UpdatePlottedImage',
            deep: true
        },
        myMapOpacity(newVal) {
            this.setOpacity(newVal);
        },
        mapOpacity(newVal) {
            this.myMapOpacity = newVal;
            if (this.overlay) this.overlay.setOpacity(newVal);
        },
        async getGeolocationEnablement(newVal) {
            if (!this.getGeolocationEnablement) {
                this.ClearAllWatchIds();
                await this.InitMapAsync();
            }
            if (this.getGeolocationEnablement) this.watchUserLocation();
        },
        geoImage: {
            handler: 'InitMapAsync',
            deep: true
        }
    }
}
</script>

<style scoped lang="scss">
#ambientationMap {
    height: 600px;
}

.map-styles {
    height: 500px;
    width: 100%;
}

.slider-map {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 35%;
}
</style>
