import {
    router,
    GetPathToFields,
    GetRoute,
    GetPathToAmbientationsSection1,
    GetPathToPenetrometrySection1,
    GetPathPrescriptionsSection1,
    GetReportsSection1Path,
    GetPathToField,
    GetRecommendationReportsPath,
} from "@/router/NewRouter";
import { Herramientas } from "@/Auxiliares/Enums";

// eslint-disable-next-line no-unused-vars
class BreadcrumbItem {
    constructor(label, path) {
        this.label = label;
        this.path = path;
    }
}

const GetDefaultState = () => {
    return {
        /** @type {BreadcrumbItem[]} */
        items: [],
    };
};

export default {
    namespaced: true,
    state: GetDefaultState(),
    getters: {
        getBreadcrumbItems(state) {
            return state.items;
        },
    },
    mutations: {
        /**
         * @param {*} state
         * @param {{producer: { id, name }, field: { id, name }, userAdmin: boolean}} param2
         */
        setBreadcrumbItemsFromUrl(state, { producer, field, userAdmin }) {
            state.items = [];

            const hasBreadCrumb = router.currentRoute.meta?.hasBreadCrumb;
            if (!hasBreadCrumb) return;

            if (userAdmin) state.items.push(new BreadcrumbItem("Productores", GetRoute("Producers").path));

            let fieldsTitle = "Mis lotes";
            if (userAdmin) fieldsTitle = `${producer.businessName}`;
            state.items.push(new BreadcrumbItem(fieldsTitle, GetPathToFields(producer.id)));

            const titleLevel3 = `${field.name}`;
            state.items.push(new BreadcrumbItem(titleLevel3, GetPathToField(producer.id, field.id)));

            if (["AmbientationsSection2", "AmbientationsSection3", "SuggestedAmbientations"].includes(router.currentRoute.name)) {
                if (!userAdmin) return;
                const path = GetPathToAmbientationsSection1(producer.id, field.id, router.currentRoute.params.ambientationId);
                const breadcrumbItem = new BreadcrumbItem(Herramientas.Ambientaciones.title, path);
                state.items.push(breadcrumbItem);
                return;
            }

            if (router.currentRoute.name == "PenetrometrySection2") {
                if (!userAdmin) return;
                state.items.push(new BreadcrumbItem("Penetrometría", GetPathToPenetrometrySection1(producer.id, field.id, router.currentRoute.params.penetrometryId)));
                return;
            }

            if (router.currentRoute.name == "PrescriptionsSection2") {
                if (!userAdmin) return;
                state.items.push(new BreadcrumbItem(Herramientas.ServicioFertilizacion.title, GetPathPrescriptionsSection1(producer.id, field.id, router.currentRoute.params.prescriptionId)));
                return;
            }

            if (router.currentRoute.name == "ReportsSection2") {
                if (!userAdmin) return;
                state.items.push(new BreadcrumbItem(Herramientas.Informes.title, GetReportsSection1Path(producer.id, field.id, router.currentRoute.params.toolReportId)));
                return;
            }

            if (router.currentRoute.name == "RecommendationReportsSection2") {
                state.items.push(new BreadcrumbItem(Herramientas.InformesDeRecomendacion.title, GetRecommendationReportsPath(producer.id, field.id, router.currentRoute.params.toolReportId)));
            }
        },
    },
    actions: {
        SetBreadcrumbItemsFromUrl({ commit, rootGetters }) {
            const producer = rootGetters["generalsStore/getProducer"];
            const field = rootGetters["generalsStore/getField"];
            const userAdmin = rootGetters["userStore/userAdmin"];
            commit("setBreadcrumbItemsFromUrl", { producer, field, userAdmin });
        },
    },
};
